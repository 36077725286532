import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave from "src/components/nonbmaWave"
import BelowFold from "src/components/belowFold"
import Disclosure from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const btfData = import("./data/btfdata.json");
const wavelpData = import("./data/data.json");
const footer = <RawFooter></RawFooter>


export default function bmTideSpb() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
      #disclaimers-module--disclaimers {
        color: #666;
        width: 600px;
        text-align:center;
        margin: 0 auto;
        line-height: 135%;
        font-size: 12px;
      }
        .download-disclosure #disclaimers-module--disclaimers p {
        width:600px !important;
        }

.bf-module--belowFold .download-section .cta-button.primary {
		  padding: 40px 84px;
  line-height: 112%;
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  border-radius: 32px;
  transition: .2s ease;
  margin: 20px auto;
  cursor: pointer;
  width: 450px;
  position: relative;
  z-index: 10;
  border: 1px solid #39b54a;
  background: #39b54a;
  background-size: 200% 100%;
  background-position: 100% 100%;
  box-shadow: 0 10px 40px -10px #23e332;
	  }
	  
.bf-module--belowFold .download-section .cta-button.primary:hover {
		transform: scale(1.1);
}

      footer {
      margin:0;
      }

       .footerlinks-module--br {
         display: none!important;
       }

       .wave-module--wave .wave-module--header img {
         padding: 8px 0 8px 8px;

       }

       .wave-module--wave .wave-module--center {
         width: 100%;
       }

       .wave-module--wave ul.wave-module--bullets li {
         padding-bottom: 10px;
         flex: 0 1 45%!important;
       }

       .wave-module--wave ul.wave-module--bullets {
         width: 100%;
    max-width: 850px;
       }
       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;
        padding: 40px 84px;
       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>PDFtoDOC New Tab - frompdftodoc.com</title></Helmet>
    <section>
      <NonbmaWave data={wavelpData}><Disclosure></Disclosure>
</NonbmaWave>
<BelowFold data={btfData} footer={footer}><Disclosure></Disclosure>
</BelowFold>
      </section>
    </HomepageLayout>
  )
}
